<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/software-service" parent-url="软件服务" current-url="虚拟机" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/n7.png" />
          <div class="about">
            <div class="title">虚拟机</div>
            <div class="content">
              指通过软件模拟的具有完整硬件系统功能的、运行在一个完全隔离环境中的完整计算机系统。在实体计算机中能够完成的工作在虚拟机中都能够实现。在计算机中创建虚拟机时，需要将实体机的部分硬盘和内存容量作为虚拟机的硬盘和内存容量。每个虚拟机都有独立的CMOS、硬盘和操作系统，可以像使用实体机一样对虚拟机进行操作。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">

        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">KVM</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <div class="company-info">
              KVM代表着键盘（Keyboard）、显示器（Video）和鼠标（Mouse），即利用一组键盘、显示器或鼠标实现对多台设备的控制，在远程调度监控方面发挥着重要作用。KVM技术可以向远程终端发送调度信息网中的各项数据资料，为下一级调度机构提供方便，这样即便下级调度机构没有建立调度数据网，也能够实现信息的共享。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">Nutanix</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-31.png">
            <div class="company-info">
              路坦力（Nutanix）虚拟计算平台是一个将计算机（服务器）层和存储层牢固地融合为一个单一,集成的应用的基础设施解决方案。与谷歌、facebook、亚马逊等领先的公共云提供商一样，Nutanix也采用一种先进的、分布式的软件架构——专门为主流企业和政府机构量身定制。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">K8s</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <div class="company-info">
              Kubernetes 是一个全新的基于容器技术的分布式架构解决方案，是 Google 开源的一个容器集群管理系统，Kubernetes 简称 K8S。它是一个一站式的完备的分布式系统开发和支撑平台，更是一个开放平台，对现有的编程语言、编程框架、中间件没有任何侵入性。提供了完善的管理工具，这些工具涵盖了开发、部署测试、运维监控在内的各个环节。Kubernetes 具有完备的集群管理能力，包括多层次的安全防护和准入机制、多租户应用支撑能力、透明的服务注册和服务发现机制、内建智能负载均衡器、强大的故障发现和自我修复能力、服务滚动升级和在线扩容能力、可扩展的资源自动调度机制、多粒度的资源配额管理能力。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">VMware</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-32.png">
            <div class="company-info">
              VMware云计算技术利用VMware混合云和原生公有云为IT组织提供在任意云中运行、管理、连接和保护的应用。凭借私有云、公有云和混合云解决方案，组织实施支持其云计算解决方案，推动业务选择和可控。公有云整合包括VMware混合云和管理多云服务 。VMware混合云产品/服务基于软件定义数据中心体系结构构建，利用VMware的计算、网络连接和存储虚拟化技术 。
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
